body {
  background: black;
  color: white;
  overflow-x: clip; /* For some reason there's huge x scroll into nothingness */
  width: 100vw;
  user-select: none;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
}

#play-buttons {
  display: flex;
  flex-direction: row;
  width: 30%;
  align-items: center;
  justify-content: space-around;
  z-index: 10; /* Above wheels */
  margin: 10px;
}

#play-buttons button {
  padding: 20px;
  font-size: 20px;
}

button {
  display: block;
  background: black;
  color: white;
  border: none;
  font-size: 36px;
  cursor: pointer;
  border-radius: 8px;
}

button:hover {
  background: dimgrey;
}

button:active {
  color: black;
}

.modal {
  background-color: black;
  z-index: 20;
  border: 1px solid white;
  position: absolute;
  width: 80%;
  top: 20vh;
  margin-left: auto;
  margin-right: auto;
}

.hidden {
  display: none;
}

.small-font {
  font-size: x-small;
}

.large-font {
  font-size: large;
}
