#legend table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 98vw;
  max-width: 1000px;
}

#legend td {
  padding: 1vw;
  cursor: pointer;
  background: #222;
  border: 2px solid black;
  box-sizing: border-box;
  height: 5vh;
  text-align: center;
}

#legend td:active {
  background-color: #333;
}

.fixed-width td {
  width: 5em;
}
