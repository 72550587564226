#footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  max-width: 1000px;
}

#footer button {
  width: 50vw;
  background-color: #222;
  border: 2px solid black;
  border-radius: 0;
}

#footer button:hover {
  background-color: #333;
}

#footer a {
  text-decoration: none;
  color: white;
  font-size: x-large;
}

