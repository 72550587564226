#wheel-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  max-width: 100vw;
  min-width: 35vw;
}

#wheel-buttons h4 {
  margin-bottom: 1vh;
}

@media screen and (max-width: 400px) {
  #wheel-buttons { max-width: 100%; }
  /* #wheel-buttons h4 { font-size: 0.8em; } */
  #wheel-buttons .arrow {
    padding: 0px;
    text-align: center;
    font-size: 30px;
  }
  .rotate-buttons-indicator {
    margin: 0px !important;
    padding: 7px 5px !important;
    border-radius: 1px !important;
  }
}

#wheel-buttons .arrow {
  background-color: #222;
  border: none;
  cursor: pointer;
  display: inline-block;
  padding: 0;
  width: 40px; /* Arrow width */
  height: 40px; /* Arrow height */
  position: relative;
}

#wheel-buttons .arrow:active {
  background-color: #555; /* Darker background color for active state */
  transform: scale(0.95); /* Slightly scale down the button to mimic a press */
}

#wheel-buttons .arrow::before,
#wheel-buttons .arrow::after {
  content: '';
  position: absolute;
  top: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  pointer-events: none;
}

#wheel-buttons .arrow::before {
  border-color: rgba(136, 183, 213, 0);
  border-width: 10px;
  margin-top: -10px;
}

#wheel-buttons .arrow.left::before {
  left: 50%; /* Center arrow horizontally */
  margin-left: -15px; /* Adjust for arrow width to center */
  border-right-color: #fff; /* Arrow color reversed */
  border-left-color: transparent; /* Make the original arrow color transparent */
}

#wheel-buttons .arrow.right::before {
  right: 50%; /* Center arrow horizontally */
  margin-right: -15px; /* Adjust for arrow width to center */
  border-left-color: #fff; /* Arrow color reversed */
  border-right-color: transparent; /* Make the original arrow color transparent */
}

.rotate-buttons-indicator {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 12px 20px;
  border-radius: 10px;
  border: 1px solid dimgrey;
  margin: 0 5px;
}

.wheel-button-group {
  text-align: center;
  /* max-width: 50vw; */

  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 30;
}

.rotate-buttons {
  display: flex;
  flex-direction: column;
  z-index: 10; /* so they can be clicked even when corner of images are above them */
}

.rotate-buttons-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
